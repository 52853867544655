/**メインページ */
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/button.js'; 
import DentalSlider from '../components/dentalslider.js';
import '../../styles/dental/dental-top.scss';
import triangleLine from '../icons/triangleline.svg';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import dentist from '../img/dentist.JPG';
import Facility1 from '../img/Facility-1.JPG';
import Facility2 from '../img/Facility-2.JPG';
import Facility3 from '../img/Facility-3.JPG';
import Facility4 from '../img/Facility-4.JPG';
import Facility5 from '../img/Facility-5.JPG';
import Facility6 from '../img/Facility-6.JPG';
import notice from '../img/notice.JPG';
import Access from '../components/access.js';
import recruit from '../img/k_visual.jpg';

// アイコンインポート
import image1 from '../icons/歯が痛い、しみる.svg';
import image1hover from '../icons/歯が痛い、しみるホバー.svg';
import image2 from '../icons/噛むと痛い.svg';
import image2hover from '../icons/噛むと痛いホバー.svg';
import image3 from '../icons/顎が痛い.svg';
import image3hover from '../icons/顎が痛いホバー.svg';
import image4 from '../icons/入れ歯を入れたい.svg';
import image4hover from '../icons/入れ歯を入れたいホバー.svg';
import image5 from '../icons/歯が揺れている.svg';
import image5hover from '../icons/歯が揺れているホバー.svg';
import image6 from '../icons/舌や頬に痺れ.svg';
import image6hover from '../icons/舌や頬に痺れホバー.svg';
import image7 from '../icons/歯が欠けた.svg';
import image7hover from '../icons/歯が欠けたホバー.svg';
import image8 from '../icons/歯の色が気になる.svg';
import image8hover from '../icons/歯の色が気になるホバー.svg';
import image9 from '../icons/歯茎から出血.svg';
import image9hover from '../icons/歯茎から出血ホバー.svg';
import image10 from '../icons/口臭が気になる.svg';
import image10hover from '../icons/口臭が気になるホバー.svg';
import image11 from '../icons/詰め物が取れた.svg';
import image11hover from '../icons/詰め物が取れたホバー.svg';
import image12 from '../icons/その他.svg';
import image12hover from '../icons/その他ホバー.svg';







function Dentaltop() {
    return(
        <div className="dental-top">
            <div className="for-pc">
                {/* key visual */}
                <section className="key-visual">
                    <div className="key-visual__flex">
                        <div className="key-visual__links">
                            <Link to="/dental-symptoms1">症状別で探す</Link>
                            <Link to="/dental-information">診療のご案内</Link>
                            <Link to="/dental-firstimpression">当院が初めての方へ</Link>
                            </div>
                            <div className="key-visual__visual">
                                <DentalSlider /> {/* スライダーコンポーネントを使用 */}
                            </div>
                    </div>
                </section>

                {/* announce */}
                <section className="news">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">お知らせ</h2>
                    </div>
                    <div className="news__wrapper">
                        <div>
                            <img src={notice} alt="News Image" />
                        </div>
                        <div className="news__content">
                            <p>当院は診療・健診ともに予約制となっております。<br />
                                また、歯科専用LINE公式アカウントにお友達登録をして頂くと、初診・再診の治療のご相談からご予約、ご予約変更もお気軽に利用して頂けるのでおすすめです。
                            </p>
                            <div className="button-wrapper">
                            <Button to="/dental-information">診療のご案内</Button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* symptom */}
                <section className="departments">
                    <h2 className="title__text">症状別で探す</h2>
                    <div className="departments__buttonbox">
                    <ButtonSubject 
                        to="/dental-symptoms1" 
                        imgSrc={image1}
                        hoverImgSrc={image1hover}
                    >
                        歯が痛い<br />しみる
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms2" 
                        imgSrc={image2}
                        hoverImgSrc={image2hover}
                    >
                        噛むと痛い
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms3" 
                        imgSrc={image3}
                        hoverImgSrc={image3hover}
                    >
                        口が開かない<br />顎が痛い
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms4" 
                        imgSrc={image4}
                        hoverImgSrc={image4hover}
                    >
                        入れ歯を作りたい
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms5" 
                        imgSrc={image5}
                        hoverImgSrc={image5hover}
                    >
                        歯が揺れている
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms6" 
                        imgSrc={image6}
                        hoverImgSrc={image6hover}
                    >
                        舌や頬に出来物や痺れ
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms7" 
                        imgSrc={image7}
                        hoverImgSrc={image7hover}
                    >
                        歯が欠けた<br />穴があいた
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms8" 
                        imgSrc={image8}
                        hoverImgSrc={image8hover}
                    >
                        歯の色が気になる
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms9" 
                        imgSrc={image9}
                        hoverImgSrc={image9hover}
                    >
                        歯茎から出血や膿
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms10" 
                        imgSrc={image10}
                        hoverImgSrc={image10hover}
                    >
                        口臭が気になる
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms11" 
                        imgSrc={image11}
                        hoverImgSrc={image11hover}
                    >
                        詰め物・被せ物が取れた
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms12" 
                        imgSrc={image12}
                        hoverImgSrc={image12hover}
                    >
                        その他
                    </ButtonSubject>
                    </div>
                    <div className="departments__img">
                    <img src={triangleMatrix} className="departments__img--topleft" />
                    <img src={triangleMatrix} className="departments__img--bottomright" />
                    </div>
                </section>

                {/* recruit */}
                <section className="recruitment">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">採用情報</h2>
                    </div>
                    <div className="recruitment__flex">
                        <div className="recruitment__image">
                            <img src={recruit} alt="採用情報" />
                        </div>
                        <div className="recruitment__text">
                            <p>当院では一緒に働く衛生士・歯科助手を募集しております<br />
                            詳しくはこちらからご確認ください
                            
                            </p>
                            <Button to="/dental-recruit">詳しく見る</Button>
                        </div>
                    </div>
                </section>

                {/* Facility */}
                <section className="facility">
                    <div className="for-pc">
                        <h2 className="title__text">
                        施設・設備紹介
                        </h2>
                        <div className="facility__grid">
                        <img className="facility__grid--1" src={Facility1}></img>
                        <img className="facility__grid--2" src={Facility2}></img>
                        <img className="facility__grid--3" src={Facility3}></img>
                        <img className="facility__grid--4" src={Facility4}></img>
                        <img className="facility__grid--5" src={Facility5}></img>
                        <img className="facility__grid--6" src={Facility6}></img>
                        <div className="facility__grid-vector"></div>
                        </div>
                        <Button to="/medical-surgery">詳しく見る</Button>
                    </div>
                </section>            

                {/* Access */}
                <Access />

            </div>

            <div className="for-sp">
                {/* key visual */}
                <section className="key-visual">
                    <div className="key-visual__visual">
                        <DentalSlider /> {/* スライダーコンポーネントを使用 */}
                    </div>
                </section>

                {/* announce */}
                <section className="news">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">お知らせ</h2>
                    </div>
                    <div className="news__wrapper">
                        <div>
                            <img src={notice} alt="News Image" />
                        </div>
                        <div className="news__content">
                            <p>当院は診療・健診ともに予約制となっております。<br />
                                また、歯科専用LINE公式アカウントにお友達登録をして頂くと、初診・再診の治療のご相談からご予約、ご予約変更もお気軽に利用して頂けるのでおすすめです。
                            </p>
                            <div className="button-wrapper">
                            <Button to="/dental-information">診療のご案内</Button>
                            </div>
                        </div>
                    </div>
                </section>

                {/* symptom */}
                <section className="departments">
                    <h2 className="title__text">症状別で探す</h2>
                    <div className="departments__buttonbox">
                    <ButtonSubject 
                        to="/dental-symptoms1" 
                        imgSrc={image1}
                        hoverImgSrc={image1hover}
                    >
                        歯が痛い<br />しみる
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms2" 
                        imgSrc={image2}
                        hoverImgSrc={image2hover}
                    >
                        噛むと痛い
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms3" 
                        imgSrc={image3}
                        hoverImgSrc={image3hover}
                    >
                        口が開かない<br />顎が痛い
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms4" 
                        imgSrc={image4}
                        hoverImgSrc={image4hover}
                    >
                        入れ歯を作りたい
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms5" 
                        imgSrc={image5}
                        hoverImgSrc={image5hover}
                    >
                        歯が揺れている
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms6" 
                        imgSrc={image6}
                        hoverImgSrc={image6hover}
                    >
                        舌や頬に出来物や痺れ
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms7" 
                        imgSrc={image7}
                        hoverImgSrc={image7hover}
                    >
                        歯が欠けた<br />穴があいた
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms8" 
                        imgSrc={image8}
                        hoverImgSrc={image8hover}
                    >
                        歯の色が気になる
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms9" 
                        imgSrc={image9}
                        hoverImgSrc={image9hover}
                    >
                        歯茎から出血や膿
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms10" 
                        imgSrc={image10}
                        hoverImgSrc={image10hover}
                    >
                        口臭が気になる
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms11" 
                        imgSrc={image11}
                        hoverImgSrc={image11hover}
                    >
                        詰め物・被せ物が取れた
                    </ButtonSubject>
                    <ButtonSubject 
                        to="/dental-symptoms12" 
                        imgSrc={image12}
                        hoverImgSrc={image12hover}
                    >
                        その他
                    </ButtonSubject>
                    </div>
                    <div className="departments__img">
                    </div>
                </section>

                {/* recruit */}
                <section className="recruitment">
                    <div className="title">
                        <img className="title__picture" src={triangleLine} alt="" />
                        <h2 className="title__text">採用情報</h2>
                    </div>
                    <div className="recruitment__flex">
                        <div className="recruitment__image">
                            <img src={recruit} alt="採用情報" />
                        </div>
                        <div className="recruitment__text">
                            <p>当院では一緒に働く衛生士・歯科助手を募集しております<br />
                            詳しくはこちらからご確認ください
                            
                            </p>
                            <Button to="/dental-recruit">詳しく見る</Button>
                        </div>
                    </div>
                </section>

                {/* Facility */}
                <section className="facility">
                    <div className="for-pc">
                        <h2 className="title__text">
                        施設・設備紹介
                        </h2>
                        <div className="facility__grid">
                        <img className="facility__grid--1" src={Facility1}></img>
                        <img className="facility__grid--2" src={Facility2}></img>
                        <img className="facility__grid--3" src={Facility3}></img>
                        <img className="facility__grid--4" src={Facility4}></img>
                        <img className="facility__grid--5" src={Facility5}></img>
                        <img className="facility__grid--6" src={Facility6}></img>
                        <div className="facility__grid-vector"></div>
                        </div>
                        <Button to="/medical-surgery">詳しく見る</Button>
                    </div>
                </section>  

                {/* Access */}
                <Access />
            </div>
        </div>






    )




}

export default Dentaltop;