import React, { useState } from 'react';
import '../../styles/components/access.scss';
import Button from '../components/button.js';
import triangleLine from '../icons/triangleline.svg';
import trianglevector from '../icons/triangle.svg';

const access = () =>{

    return(
        <section className="access">
            <div className="for-pc">
                <div className="title">
                    <img className="title__picture" src={triangleLine} alt="" />
                    <h2 className="title__text">アクセス</h2>
                </div>
                <div className="access__flex">
                    <div className="access__contents">
                        <ul className="access__contents__list">
                            <li><span>病院名</span> <span>医療法人県央会県央医科歯科クリニック</span></li>
                            <li><span>所在名</span> <span className="access__contents__list--AlignLeft"><p>〒370-3524</p><p>群馬県高崎市中泉町610−2</p></span></li>
                            <li><span>医科電話番号</span> <span>027−372-1231</span></li>
                            <li><span>歯科電話番号</span> <span>027−395-6677</span></li>
                        </ul>
                        <div className="access__contents__text">
                            <div className="access__contents__text__title">
                                <img src={trianglevector} alt="" />
                                <p>お車でお越しの方</p>
                                <img src={trianglevector} alt="" />
                            </div>
                            <p>敷地内駐車場あります22台まで駐車可能</p>
                        </div>
                        <div className="access__contents__text">
                            <div className="access__contents__text__title">
                                <img src={trianglevector} alt="" />
                                <p>バスでお越しの方</p>
                                <img src={trianglevector} alt="" />
                            </div>
                            <p>県央医科歯科クリニック前バス停をおりてすぐ目の前になります。</p>
                        </div>
                    </div>
                    <div className="access__map">
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.5537551179514!2d139.00045417609047!3d36.37158649174287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601e8cf667192069%3A0xc25fa10385275524!2z44CSMzcwLTM1MjQg576k6aas55yM6auY5bSO5biC5Lit5rOJ55S677yW77yR77yQ4oiS77ySIOecjOWkruOCr-ODquODi-ODg-OCrw!5e0!3m2!1sja!2sjp!4v1692464821183!5m2!1sja!2sjp" 
                        width="600" 
                        height="450" 
                        style={{ border: 0 }} 
                        allowFullScreen="" 
                        loading="lazy"
                    ></iframe>
                    </div>
                </div>
                <Button to="/contact">お問い合わせ</Button>
            </div>
            <div className="for-sp">
                <div className="access__map">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.5537551179514!2d139.00045417609047!3d36.37158649174287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601e8cf667192069%3A0xc25fa10385275524!2z44CSMzcwLTM1MjQg576k6aas55yM6auY5bSO5biC5Lit5rOJ55S677yW77yR77yQ4oiS77ySIOecjOWkruOCr-ODquODi-ODg-OCrw!5e0!3m2!1sja!2sjp!4v1692464821183!5m2!1sja!2sjp" 
                        width="300" 
                        height="300" 
                        style={{ border: 0 }} 
                        allowFullScreen="" 
                        loading="lazy"
                    ></iframe>
                </div>
                <div className="access__contents">
                        <ul className="access__contents__list">
                            <li><span>病院名</span> <span>医療法人県央会県央医科歯科クリニック</span></li>
                            <li><span>所在名</span> <span className="access__contents__list--AlignLeft"><p>〒370-3524</p><p>群馬県高崎市中泉町610−2</p></span></li>
                            <li><span>電話番号</span> <span>027-372-1231</span></li>
                            <li><span>電話番号（歯科直通）</span> <span>027-395-6677</span></li>
                        </ul>
                        <div className="access__contents__text">
                            <div className="access__contents__text__title">
                                <img src={trianglevector} alt="" />
                                <p>お車でお越しの方</p>
                                <img src={trianglevector} alt="" />
                            </div>
                            <p>敷地内駐車場あります22台まで駐車可能</p>
                        </div>
                        <div className="access__contents__text">
                            <div className="access__contents__text__title">
                                <img src={trianglevector} alt="" />
                                <p>バスでお越しの方</p>
                                <img src={trianglevector} alt="" />
                            </div>
                            <p>県央医科歯科クリニック前バス停をおりてすぐ目の前になります。</p>
                        </div>
                    </div>
            </div>
        </section>


    );

}

export default access;