/*フロントページ*/
import React, { useState } from 'react';
import '../styles/top.scss';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Button from './components/button.js';
import ImageSlider from './components/slider.js';
import DentalDevice from './img/歯医者.jpeg';
import MedicalDevice from './img/医科.jpeg';
import triangleLine from './icons/triangleline.svg';
import recruit from './img/k_visual.jpg';
import trianglevector from './icons/triangle.svg';
import examinationroom from './img/家族.jpeg';
import dentistExaminationroom from './img/口.jpeg';
import Access from './components/access';
import Maintenance from './components/Maintenance';


function Top() {
    const [state, setState] = useState(false)
    const openDrawer = () => {setState(!state);}
  
    return (
        <>
            <div className="for-pc">
                <div className="top-page">
                    {/* 1. key visual */}
                    <section className="key-visual">
                        <div className="key-visual__flex">
                            <div className="key-visual__links">
                                <Link to="/medical-top">医科について</Link>
                                <Link to="/dental-top">歯科について</Link>
                            </div>
                            <div className="key-visual__visual">
                                <ImageSlider /> {/* スライダーコンポーネントを使用 */}
                            </div>
                        </div>
                    </section>
            
                    {/* 2. To */}
                    <section className="image-links">
                        <div className="image-links__wrapper">
                            <div className="image-links__medical">
                                <img src={MedicalDevice} alt="医科について" />
                                <p>当院では多くの方に親しまれる歯科医を目指しています。
        お気軽にご相談、ご来院ください。
                                </p>
                                <div className="buttonwrapper">
                                    <Button className="image-links__button" to="/medical-top">医科ページへ</Button>
                                </div>
                            </div>
                            <div className="image-links__dental">
                                <img src={DentalDevice} alt="歯科について" />
                                <p>私たちが目指すのは、『地域の皆さまに信頼されるかかりつけ医』です。
        どうぞお気軽にご相談、ご来院ください。
                                </p>
                                <div className="buttonwrapper">
                                    <Button className="image-links__button" to="/dental-top">歯科ページへ</Button>
                                </div>
                            </div>
                        </div>
                    </section>
            
                    {/* 3. フリーテキストおよびフリー画像のセクション */}
                    <section className="free-section">
                        <div className="free-section__concept">    
                            <div className="free-section__wrapper"> 
                                <div className="free-section__image">
                                    <img src={examinationroom} />
                                </div>
                                <div className="free-section__text">小さなお子様からご高齢者まで、家族で通えるクリニックです。</div>
                            </div>                
                            <div className="free-section__wrapper"> 
                                <div className="free-section__text">お口の中から体の中まで、健康上のお悩みに応えていきます。</div>
                                <div className="free-section__image">
                                    <img src={dentistExaminationroom} alt="フリー画像" />
                                </div>
                            </div>
                        </div>
                    </section>
            
                    {/* 4. お知らせセクション */}
                    <section className="news">
                        <div className="title">
                            <img className="title__picture" src={triangleLine} alt="" />
                            <h2 className="title__text">お知らせ</h2>
                        </div>
                        <ul className="news__contents">
                            <li><span>2024-12-06</span> <span><Link className="underbar" to="/information-02">年末年始の休診日のお知らせ</Link></span></li>
                            <li><span>2024-08-05</span> <span>当院の夏季休診は8月12日月曜日から15日木曜日になります</span></li>
                            <li><span>2024-04-05</span> <span><Link className="underbar" to="/information-01">『帯状疱疹予防接種の公費助成』</Link>について更新しました。</span></li>
                            <li><span>2024-01-10</span> <span>現在ホームページの一部が改修中となっており、多大なるご迷惑をおかけしております。</span></li>
                            <li><span>2023-10-25</span> <span>ホームページが長期間非表示になっていたことをお詫びいたします</span></li>
                            <li><span>2023-03-30</span> <span>スマートフォン版HPをリリースいたしました</span></li>
                        </ul>
                    </section>
            
                    {/* 5. 採用情報セクション */}
                    <section className="recruitment">
                        <div className="title">
                            <img className="title__picture" src={triangleLine} alt="" />
                            <h2 className="title__text">採用情報</h2>
                        </div>
                        <div className="recruitment__flex">
                            <div className="recruitment__image">
                                <img src={recruit} alt="採用情報" />
                            </div>
                            <div className="recruitment__text">
                                <p>歯科では一緒に働くスタッフを募集しています。
                                    <br />
                                    少しでも興味を持っていただけましたら、是非ご応募をお願いします。
                                </p>
                                <div className="buttonwrapper">
                                    <Button to="/dental-recruit">詳しく見る</Button>
                                </div>
                            </div>
                        </div>
                    </section>
            
                    {/* 6. アクセスセクション */}
                    <Access />

            
                </div>
            </div>
            <div className="for-sp">
                <div className="top-page">
                        {/* 1. key visual */}
                        <section className="key-visual">
                            <div className="key-visual__flex">
                                <div className="key-visual__visual">
                                    <ImageSlider /> {/* スライダーコンポーネントを使用 */}
                                </div>
                            </div>
                        </section>
                
                        {/* 2. To */}
                        <section className="image-links">
                            <div className="image-links__wrapper">
                                <div className="image-links__medical">
                                    <img src={MedicalDevice} alt="医科について" />
                                    <p>当院では多くの方に親しまれる歯科医を目指しています。
            お気軽にご相談、ご来院ください。
                                    </p>
                                    <div className="buttonwrapper">
                                        <Button className="image-links__button" to="/medical-top">医科ページへ</Button>
                                    </div>
                                </div>
                                <div className="image-links__dental">
                                    <img src={DentalDevice} alt="歯科について" />
                                    <p>私たちが目指すのは、『地域の皆さまに信頼されるかかりつけ医』です。
            どうぞお気軽にご相談、ご来院ください。
                                    </p>
                                    <div className="buttonwrapper">
                                        <Button className="image-links__button" to="/dental-top">歯科ページへ</Button>
                                    </div>
                                </div>
                            </div>
                        </section>
                
                        {/* 3. フリーテキストおよびフリー画像のセクション */}
                        <section className="free-section">
                            <div className="free-section__concept">    
                                <div className="free-section__wrapper"> 
                                    <div className="free-section__image">
                                        <img src={examinationroom} />
                                    </div>
                                    <div className="free-section__text">小さなお子様からご高齢者まで、家族で通えるクリニックです。</div>
                                </div>                
                                <div className="free-section__wrapper"> 
                                    <div className="free-section__text">お口の中から体の中まで、健康上のお悩みに応えていきます。</div>
                                    <div className="free-section__image">
                                        <img src={dentistExaminationroom} alt="フリー画像" />
                                    </div>
                                </div>
                            </div>
                        </section>
                
                        {/* 4. お知らせセクション */}
                        <section className="news">
                            <div className="title">
                                <img className="title__picture" src={triangleLine} alt="" />
                                <h2 className="title__text">お知らせ</h2>
                            </div>
                            <ul className="news__contents">
                                <li><span>2024-12-06</span> <span><Link className="underbar" to="/information-02">年末年始の休診日のお知らせ</Link></span></li>
                                <li><span>2024-08-05</span> <span>当院の夏季休診は8月12日月曜日から15日木曜日になります</span></li>
                                <li><span>2024-04-05</span> <span><Link className="underbar" to="/information-01">『帯状疱疹予防接種の公費助成』</Link>について更新しました。</span></li>
                                <li><span>2024-01-10</span> <span>現在ホームページの一部が改修中となっており、多大なるご迷惑をおかけしております。</span></li>
                                <li><span>2023-10-25</span> <span>ホームページが長期間非表示になっていたことをお詫びいたします</span></li>
                                <li><span>2023-03-30</span> <span>スマートフォン版HPをリリースいたしました</span></li>
                            </ul>
                        </section>
                
                        {/* 5. 採用情報セクション */}
                        <section className="recruitment">
                            <div className="title">
                                <img className="title__picture" src={triangleLine} alt="" />
                                <h2 className="title__text">採用情報</h2>
                            </div>
                            <div className="recruitment__flex">
                                <div className="recruitment__image">
                                    <img src={recruit} alt="採用情報" />
                                </div>
                                <div className="recruitment__text">
                                    <p>歯科では一緒に働くスタッフを募集しています。
                                        <br />
                                        少しでも興味を持っていただけましたら、是非ご応募をお願いします。
                                    </p>
                                    <div className="buttonwrapper">
                                        <Button to="/dental-recruit">詳しく見る</Button>
                                    </div>
                                </div>
                            </div>
                        </section>
                
                        {/* 6. アクセスセクション */}
                        <Access />

                
                </div>
            </div>
        </>
    );    
  }
  
  export default Top;
  